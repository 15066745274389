import router from '../../router'

export default {
  setUser(state, payload) {
    state.userId = payload.id;
    state.username = payload.username
    state.isStaff = payload.isStaff;
    state.role = payload.role
    state.userLocation.id = parseInt(payload.userLocationId)
    state.userLocation.snakeCase = payload.userLocationSnakeCase
    state.userLocation.camelCase = payload.userLocationCamelCase
    state.userLocation.capitalCase = payload.userLocationCapitalCase
    state.email = payload.email;
    state.token = payload.token;
  },
  updateAuthError(state, payload) {
    state.authError = payload.authError
  },
  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('isStaff');
    localStorage.removeItem('role');
    localStorage.removeItem('userLocationId');
    localStorage.removeItem('userLocationSnakeCase');
    localStorage.removeItem('userLocationCamelCase');
    localStorage.removeItem('userLocationCapitalCase');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    this.commit("setUser", {
      userId: '',
      username: '',
      isStaff: '',
      role: '',
      userLocationId: '',
      userLocationSnakeCase: '',
      userLocationCamelCase: '',
      userLocationCapitalCase: '',
      email: '',
      token: ''
    })
    router.push({ path: '/login' })
  }
};
