<template>
  <li
    class="card selectable"
    v-if="(userIsNotTourist && order.userRole !== 'tourist')
      || order.username === email
      "
    @click="openOrderModal"
  >
    <a>
      <base-card
        :borderColor="borderColor"
        :isSubmitted="order.status === 'submitted'"
      >
        <header>

          <h3 v-if="queryLocation === 'all'">{{ displayLocation }}</h3>

          <h3>#{{ order.id }}</h3>

          <h3>{{ order.username }}</h3>

          <h3 class="archiveOrderStatus">{{ currentStatus }}</h3>


          <h3>{{ timeOrDate }}</h3>


        </header>
      </base-card>
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalCase } from 'change-case';
export default {
  props: ['order', 'queryTime', 'queryLocation'],
  data() {
    return {
      // viewItems: false,
    };
  },
  computed: {
    ...mapGetters(['locations', 'userIsNotTourist', 'email']),
    displayLocation() {
      return capitalCase(this.locations.get('name', this.order.locationId))
    },
    currentStatus() {
      return capitalCase(this.order.status)
    },
    timeOrDate() {
      return this.queryTime === 'today' ? `Submitted: ${this.order.submissionTimeLocal}` : this.order.submissionDateLocal;
    },
    borderColor() {
      let color = ''
      if (this.order.status === 'submitted') {
        color = 'orange'
      } else if (this.order.status === 'packaged') {
        color = 'blue';
      } else if (this.order.status === 'sold') {
        color = 'green';
      } else if (
        this.order.status === 'customer_canceled'
        || this.order.status === 'store_canceled'
        || this.order.status === 'abandoned'
      ) {
        color = 'var(--accent-background)';
      }
      return color
    }
  },
  methods: {
    openOrderModal() {
      this.$emit('open-order-modal', {
        order: this.order,
        queryTime: this.queryTime,
      })
    },
  },
}

</script>

<style lang="scss" scoped>
@import 'src/components/UI/_themes.scss';

header {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


h3 {
  font-size: 1.1rem;
  margin: 0;
  padding: 5px;
}


.archiveOrderStatus {
  font-style: italic;
  // width: 70px;
}

.selectable:hover {
  cursor: pointer;
}

span {
  display: flex;
  justify-content: right;
}

span h4 {
  padding-right: 40px;
}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 620px) {
  header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 550px) {
  table {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 440px) {
  span h4 {
    padding-right: 0;
  }
}
</style>
