<template>
  <base-modal
    :title="title"
    @close="close"
  >

    <template #default>
      <div class="section-row">
        <div class="section">
          <h3>Status</h3>
          <div class="row">

            <div class="status-info">
              <p class="label">{{ statusLabel }}</p>
              <p>{{ capitalCase(order.status) }}</p>
              <p
                v-if="queryTime !== 'today'"
                class="label"
              >Date: {{ order.submissionDateLocal }}</p>
              <p class="label">Submitted: {{ order.submissionTimeLocal }}</p>
              <p
                v-if="order.packagedTimeLocal !== ''"
                class="label"
              >Packaged: {{ order.packagedTimeLocal }}</p>
              <p
                v-if="order.finishedTimeLocal !== ''"
                class="label"
              >{{ capitalCase(order.status) }}: {{ order.finishedTimeLocal }}</p>
            </div>

            <div
              v-if="queryTime === 'today' && !isLastStatusSelected"
              class="status-info"
            >
              <p class="label">Update To</p>
              <span>
                <!-- <button>{{ capitalCase(updateButtonStatus) }}</button> -->
                <button
                  class="dark-button"
                  @click="updateSelectedAndPatch"
                >{{ updateButtonStatus }}</button>
              </span>
            </div>
            <div v-if="queryTime === 'today'">
              <p class="label">Or Other</p>
              <span>
                <v-select
                  class="bold selector"
                  :reduce="status => status.label"
                  :clearable="false"
                  :options="selectableStatusList"
                  v-model="selectedOrderStatus"
                  @option:selected="patchOrderStatus"
                />
                <!-- <button>{{ capitalCase(order.status) }}</button> -->
              </span>
            </div>

          </div>

        </div>
        <div class="section">
          <h3>Customer Info</h3>
          <p class="cus-info"><span class="bold">Username:</span> {{ order.username }}</p>
          <p class="cus-info"><span class="bold">Phone:</span> {{ formattedPhone }}</p>
          <p class="cus-info"><span class="bold">Email:</span> {{ order.userEmail }}</p>
        </div>

      </div>

      <h3>Order Summary</h3>
      <div class="order-items-table-container">
        <table class="order-items-table">

          <tr class="bold">
            <td class="top-left-cell">Product Type</td>
            <td>Product Name</td>
            <td>Vendor Name</td>
            <td>Quantity</td>
            <td>Price Each</td>
            <td class="top-right-cell">Total</td>
          </tr>

          <OrderItemRow
            v-for="item in order.items"
            :key="item.id"
            :item="item"
            @add-to-order-total="addToOrderTotal"
            @reset-order-total="resetOrderTotal"
          />


          <!-- Table Footer -->
          <tr>
            <td
              v-if="!isDiscount"
              class="no-right-side"
            ></td>
            <td
              v-else
              class="no-right-side"
            ></td>
            <td class="no-sides"></td>
            <td class="no-sides"></td>
            <td class="no-sides"></td>
            <td
              v-if="!isDiscount && !order.isMedical"
              class="no-sides"
            ></td>
            <td
              class="no-left-side text-overflow color-accent"
              v-if="isDiscount && !order.isMedical"
            >
              {{ discount.name }} &lrm;
            </td>
            <td
              class="no-left-side text-overflow color-accent"
              v-if="order.isMedical && !isDiscount"
            >
              Medical Order &lrm;
            </td>
            <td
              class="no-left-side text-overflow color-accent"
              v-if="order.isMedical && isDiscount"
            >
              {{ discount.name }} | Medical Order &lrm;
            </td>
            <td class="bold ">
              <span :class="{
                totalSlash: isDiscount && orderTotal !== 0,
                'color-accent': !isDiscount && orderTotal !== 0,
              }">
                ${{ orderTotal }}
              </span>
              <span
                v-if="isDiscount && orderTotal !== 0"
                class="color-accent"
              >
                ${{ discountedOrderTotal }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template #actions>
      <base-button @click="close">Okay</base-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../UI/BaseModal.vue'
import OrderItemRow from './OrdersPage/OrderItemRow.vue'
import { mapGetters } from 'vuex';
import { capitalCase, snakeCase } from "change-case";

export default {
  emits: ['close-modal', 'update-orders', 'order-packaged'],
  props: ['order', 'queryTime'],
  components: {
    BaseModal,
    OrderItemRow,
  },
  data() {
    return {
      orderTotal: 0,
      selectedOrderStatus: '',
    }
  },
  computed: {
    ...mapGetters(['statusList']),
    isDiscount() {
      return this.order.discount.name !== "no discount"
    },
    // discount() {
    //   if (this.isDiscount) {
    //     return this.order.discount;
    //   } else {
    //     return {
    //       amount: 0,
    //       type: "percentage",
    //       name: "",
    //     };
    //   }
    // },
    discountedOrderTotal() {
      if (this.order.discount.type === "percentage") {
        return this.orderTotal - (this.orderTotal * this.order.discount.amount) / 100;
      } else {
        return this.orderTotal - this.order.discount.amount;
      }
    },
    title() {
      return `Order #${this.order.id} - ${this.order.username}`
    },
    statusLabel() {
      return this.queryTime === 'today' ? 'Current Status' : 'Final Status'
    },
    formattedPhone() {
      const p = this.order.userPhone
      return p.length !== 10 ? 'None' : `(${p.substring(0, 3)}) ${p.substring(3, 6)}-${p.substring(6, 10)}`
    },
    selectableStatusList() {
      return this.statusList.slice(1, this.statusList.length)
    },
    isLastStatusSelected() {
      return this.selectableStatusList[this.selectableStatusList.length - 1].label === this.selectedOrderStatus
    },
    updateButtonStatus() {
      if (!this.isLastStatusSelected) {
        const selectableStatusListValues = this.selectableStatusList.map(item => item.value)
        const currentStatusIndex = selectableStatusListValues.indexOf(this.order.status)
        return this.selectableStatusList[currentStatusIndex + 1].label
      } else {
        return ''
      }
    },
    patchPayload() {
      return {
        orderId: this.order.id,
        orderStatus: snakeCase(this.selectedOrderStatus),
        location: this.order.locationId
      };
    },
  },
  methods: {
    capitalCase,
    addToOrderTotal(lineTotal) {
      this.orderTotal += lineTotal;
    },
    resetOrderTotal() {
      this.orderTotal = 0;
    },
    close() {
      this.$emit('close-modal')
    },
    initStatusDisplay() {
      this.selectedOrderStatus = capitalCase(this.order.status);
    },
    // toggleViewItems() {
    //   this.viewItems = !this.viewItems;
    // },
    updateSelectedAndPatch() {
      this.selectedOrderStatus = this.updateButtonStatus
      this.patchOrderStatus()
    },
    async patchOrderStatus() {
      try {
        await this.$store.dispatch('patchOrderStatus', this.patchPayload);
        await this.$store.dispatch('getOrders', ['?time=today', 'today']);
        if (this.patchPayload.orderStatus !== 'submitted') {
          this.$emit('order-packaged')
        }

        this.close()
      } catch (error) {
        console.error(error)
      }
    },
  },
  created() {
    this.initStatusDisplay();
  }

}
</script>

<style scoped lang="scss">
.row {
  display: flex;
}

.section-row {
  display: flex;
  justify-content: space-between;
}

.section {
  display: flex;
  flex-direction: column;
}

.status-info {
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 0;
}

.info {
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 15px;
}

.label {
  font-size: 11px;
  font-style: italic;
  margin: 0;
}

.order-items-table-container {
  display: flex;
  justify-content: center;
}

.order-items-table {
  margin: 0;
}

.no-sides {
  border-left-style: hidden;
  border-right-style: hidden;
}

.no-left-side {
  border-left-style: hidden;
}

.no-right-side {
  border-right-style: hidden;
}

.text-overflow {
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0px;
  direction: rtl;
}

.color-accent {
  color: var(--highlight-text-color)
}

.totalSlash {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 1px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

p {
  margin: 5px 0 5px 0;
}

table {
  border-collapse: separate !important;
}

:deep(th),
:deep(tr),
:deep(td) {
  border: 1.5px solid var(--accent-background);
  border-collapse: collapse;
  padding: 5px;
}

.top-left-cell {
  border-top-left-radius: 5px;
}

.top-right-cell {
  border-top-right-radius: 5px;
}

.bottom-left-cell {
  border-bottom-left-radius: 5px;
}

.bottom-right-cell {
  border-bottom-right-radius: 5px;
}

button {
  font-size: medium;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 5px 8px;
  cursor: pointer;
}

button:hover {
  border-color: var(--accent-background);
}

.selector {
  font-size: 12px;
}

.cus-info {
  font-size: 14px;
}

@media (max-width: 710px) {
  .section-row {
    flex-direction: column;
  }

}

@media screen and (max-width: 640px) {
  .order-items-table {
    font-size: 2.5vw;
  }
}
</style>