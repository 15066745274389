<template>
  <div id="user-nav">
    <h4
      v-if="staffLoggedIn"
      class="cap"
      id="username"
    >User: {{ username }}</h4>
    <span>
      <button
        v-if="staffLoggedIn"
        class="dark-button"
        id="logout-button"
        @click="logout"
      >Sign Out</button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['username']),
    staffLoggedIn() {
      return this.username !== null;
    },
  },
  methods: {
    logout() {
      this.$store.commit('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
#username {
  margin-right: 10px;
}

#user-nav {
  display: flex;
  flex-direction: row;
  right: 20px;
  position: absolute;

  h4 {
    color: white;
    margin-block: 0.5em;
    font-size: 16px;
  }

  span {
    display: flex;
    justify-content: right;

  }
}
</style>