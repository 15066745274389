export default {
  strainsUrl(state) {
    // use until strains moved to django
    return state.productionStrainsApi
      ? 'https://api.westernoregondispensary.com/strains'
      : 'http://190.168.1.29:4000/strains';
  },
  dbStrains(state) {
    return state.dbStrains;
  }
};
