<template>
  <li class="card">
    <!-- Special Card -->
    <base-card :class="[editMode && id === editSpecialValues.id ? 'edit-light' : '']">
      <!-- Top Row -->
      <header>
        <!-- Title -->
        <div>
          <h3>{{ title }}</h3>
          <p
            v-if="productCategory !== ''"
            id="category"
          >({{ productCategory }} special)</p>
        </div>
        <!-- Locations -->
        <ul>
          <li
            v-for="location in locations"
            :key="location"
            class="locations"
          >
            {{ location }}
          </li>
        </ul>
        <!-- Buttons -->
        <span>
          <base-button
            mode="flat"
            @click="editSpecial(id)"
          >Edit</base-button>
          <base-button
            mode="flat"
            @click="deleteSpecial(id)"
          >Delete</base-button>
        </span>
      </header>
      <!-- Second Row -->
      <!-- Description -->
      <p id="description">{{ description }}</p>
      <!-- Timing -->
      <div
        v-if="everyDay"
        class="timing"
      >
        <h4>Every Day</h4>
      </div>
      <div
        v-else-if="timingSelection === 'reoccurring'"
        class="timing"
      >
        <h4>Every {{ reoccurringWeekend }} (upcoming below)</h4>
      </div>
      <!-- Dates -->
      <div
        v-if="timingSelection !== 'every-day'"
        class="timing"
      >
        <span>
          <label for="datetime-start">From</label>
          <input
            type="datetime-local"
            :value="isoStart"
            disabled
          />
        </span>
        <span>
          <label for="datetime-start">Until</label>
          <input
            type="datetime-local"
            :value="isoEnd"
            disabled
          />
        </span>
      </div>
    </base-card>
  </li>
</template>

<script>
export default {
  props: [
    'id',
    'title',
    'description',
    'locations',
    'storeLocation',
    'start',
    'end',
    'reoccurringWeekend',
    'endNumber',
    'productCategory',
    'editMode',
    'editSpecialValues',
    'userIsNotTourist'
  ],
  emits: ['delete-special', 'edit-special'],
  computed: {
    isoStart() {
      let start_date = new Date(this.start.replace(" ", "T"));
      let formattedDate = start_date.toISOString().slice(0, 16);
      return formattedDate;
    },
    isoEnd() {
      let end_date = new Date(this.end.replace(" ", "T"));
      let formattedDate = end_date.toISOString().slice(0, 16);
      return formattedDate;
    },
    everyDay() {
      return this.endNumber > 30000000000000;
    },
    timingSelection() {
      if (this.everyDay) {
        return 'every-day';
      } else if (!this.everyDay && this.reoccurringWeekend !== '') {
        return 'reoccurring';
      } else {
        return 'window';
      }
    },
  },
  methods: {
    editSpecial() {
      const specialObj = {
        id: this.id,
        title: this.title,
        description: this.description,
        locations: this.locations,
        storeLocation: this.storeLocation,
        start: this.start,
        end: this.end,
        timingSelection: this.timingSelection,
        reoccurringWeekend: this.reoccurringWeekend,
        productCategory: this.productCategory,
      };
      this.$emit('edit-special', specialObj);
    },
    deleteSpecial(id) {
      console.log('delete')
      console.log('userIsNotTourist', this.userIsNotTourist)
      // if (this.userIsNotTourist) {
      this.$store.dispatch('deleteSpecial', id);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
li.card {
  margin: auto;
  max-width: 40rem;
}

li.locations {
  /* background-color: aqua; */
  font-size: 11px;
  font-style: italic;
  margin: 0 2px;
  list-style-type: none;
}

ul {
  display: flex;
  flex-direction: row;
  margin: 0;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  margin: 0 4px;
  font-size: small;
}

h3 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

p {
  margin: 0.5rem 0;
}

#description {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
  color: #ce5c00;
}

a:hover,
a:active {
  color: #c89300;
}

@media screen and (max-width: 620px) {
  header {
    flex-direction: column-reverse;
  }

  header ul {
    padding: 0;
  }

  span label {
    display: inline-block;
    width: 32px;
  }

  .timing {
    display: flex;
    flex-direction: column;
  }
}
</style>