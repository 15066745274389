<template>
  <tr>
    <td class="cap">{{ item.productType }}</td>
    <td class="cap">{{ item.productName }}</td>
    <td class="cap">{{ item.vendorName }}</td>
    <td>{{ quantity }}</td>
    <td>{{ displayPriceEach }}</td>
    <td>{{ displayLineTotal }}</td>
  </tr>
</template>

<script>
// import { mapGetters } from 'vuex';

export default {
  emits: ['add-to-order-total', 'reset-order-total'],
  props: ['item'],
  data() {
    return {};
  },
  computed: {
    // ...mapGetters(['flowerPricePoints']),
    hasPricepoints() {
      return this.item.count === null
    },
    isFlower() {
      return this.item.productType === 'flower'
    },
    isBulkFlower() {
      return this.item.productName.includes('1/2 OZ')
    },
    quantity() {
      return this.hasPricepoints ? `${this.item.pricepoint.quantity} g` : this.item.count;
    },
    displayPriceEach() {
      return this.hasPricepoints
        ? `$${this.item.priceEach} g`
        : `$${this.item.priceEach}`;
    },
    lineTotal() {
      return this.hasPricepoints
        ? this.item.pricepoint.price
        // : (this.item.count * this.item.priceEach).toFixed(2);
        : Math.round(((this.item.count * this.item.priceEach) + Number.EPSILON) * 100) / 100
    },
    displayLineTotal() {
      return `$${this.lineTotal}`;
    },
  },
  methods: {
    addToOrderTotal() {
      this.$emit('add-to-order-total', this.lineTotal);
    },
    resetOrderTotal() {
      this.$emit('reset-order-total');
    },
  },
  mounted() {
    this.addToOrderTotal(this.lineTotal);
  },
  unmounted() {
    this.resetOrderTotal();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/components/UI/_themes.scss';

li.card {
  margin: auto;
  max-width: 40rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}


header {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  font-size: 1.1rem;
  margin: 0;
}

span {
  display: flex;
  justify-content: right;
}

span h4 {
  padding-right: 40px;
}

@media screen and (max-width: 620px) {
  header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 440px) {
  span h4 {
    padding-right: 0;
  }
}
</style>
