<template>
  <li class="card">
    <base-card>
      <header>
        <h3>{{ name }}</h3> 
        <span>
          <h4 :id="feel"> {{ feel }}</h4> 
          <base-button mode='flat' @click="deleteStrain(id)">Delete</base-button>
        </span>
      </header>
      
    </base-card>
  </li>
</template>

<script>
export default {
  props: ['id','name','feel'],
  emits: ['delete-strain'],
  computed: {},
  methods: {
    deleteStrain() { this.$emit('delete-strain', this.id) }
  }
}
</script>

<style lang="scss" scoped>

@import "src/components/UI/_themes.scss";

li.card {
  margin: auto;
  max-width: 40rem;
}

header {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  font-size: 1.1rem;
  margin: 0;
}

span {
  display: flex;
  justify-content: right;
}

span h4 {
  padding-right: 40px;
}

#Uplifting {
  color:rgb(197, 47, 47); 
}

#Balanced {
  color:rgb(72, 137, 197); 
}

#Relaxing {
  color:rgb(123, 53, 177); 
}

#CBD {
  color:#12da22; 
}

@media screen and (max-width: 620px) {
  header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 440px) {
span h4 {
  padding-right: 0;
}
}
</style>