<template>
  <SimpleHeader>Dashboard</SimpleHeader>
  <div id="flex-container">
    <div id="column-container">
      <div
        v-for="page in pages"
        v-bind:key="page"
        class="page-container"
      >
        <router-link
          v-if="page.access"
          :to="page.url"
        >
          <div class="page-title">
            <base-card
              class="title"
              v-bind:style="{ backgroundColor: page.color }"
            >
              <h2>{{ page.name }}</h2>
            </base-card>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleHeader from '../../UI/SimpleHeader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SimpleHeader,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['role']),
    pages() {
      return [
        {
          name: 'Orders',
          url: '/orders',
          color: 'rebeccapurple',
          access: true,
        },
        {
          name: 'Specials',
          url: '/specials',
          color: 'cadetblue',
          access: this.specialsAccess(),
        },
        {
          name: 'Strains',
          url: '/strains',
          color: 'royalblue',
          access: this.strainsAccess(),
        },
        {
          name: 'Analytics',
          url: '/analytics',
          color: '#2F2F2F',
          access: this.strainsAccess(),
        },
      ];
    },
  },
  methods: {
    specialsAccess() {
      return ['manager', 'regional', 'tourist'].includes(this.role);
    },
    strainsAccess() {
      return ['regional', 'tourist'].includes(this.role);
    },
    skipToSingleOption() {
      const pagesWithAccess = this.pages.filter((page) => {
        return page.access;
      }).length;
      if (pagesWithAccess < 2) {
        this.$router.push(this.pages[0].url);
      }
    },
  },
  mounted() {
    this.skipToSingleOption();
    // set page title
    document.title = 'Admin Dashboard';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);
  },
  watch: {
    pages() { },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--primary-background);
  background-color: var(--accent-background);
  text-align: center;
  margin-right: 20px !important;
  margin-left: 20px !important;
}

#flex-container {
  display: flex;
  justify-content: center;
}

#column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-container {
  margin: 40px 0 0 0;
}

.base-card {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 580px !important;
}

// .page-title {
//   width: 100vw;
//   display: flex;
//   justify-content: center;
// }

a {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  :deep(.base-card) {
    width: 97vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
