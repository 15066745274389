export default {
  fetchStrains(context) {
    fetch(context.getters.strainsUrl)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response);
        }
      })
      .then(data => {
        data = JSON.parse(data);
        const results = [];
        for (const id in data) {
          results.push({
            id: data[id].id,
            name: data[id].name,
            feel: data[id].feel
          });
        }
        context.commit('updateDbStrains', {
          dbStrains: results
        });
      })
      .catch(error => {
        console.log(error);
        console.log('there was an error in the fetch GET promise chain');
        this.isLoading = false;
        this.error = 'Failed to fetch data please try again later';
      });
  },
  postStrain(context, payload) {
    // fetch('http://192.168.1.29:4000/strains', {
    fetch('https://api.westernoregondispensary.com/strains', {
      method: 'POST',
      accept: 'application/json',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        name: payload.strain,
        feel: payload.feel
      })
    })
      .then(response => {
        console.log(response);
        setTimeout(() => {
          context.dispatch('fetchStrains');
        }, 200);

        if (response.ok) {
          // ...
        } else {
          throw new Error('Could not save data!');
        }
      })
      .catch(error => {
        this.error = error.message;
        console.log(error);
      });
  },
  deleteStrain(context, payload) {
    fetch(`${context.getters.strainsUrl}/${payload.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application-json'
      }
    }).then(response => {
      if (response.ok) {
        context.dispatch('fetchStrains');
      } else {
        throw new Error('Could not delete data!');
      }
    });
  }
};
