<template>
  <OrdersHeader
    @query-location="updateQueryLocation"
    @query-status="updateQueryStatus"
    @view-archive="updateQueryTime"
  />

  <div class="body-container">
    <div class="outer-show-container">
      <div class="inner-show-container">
        <base-card
          class="title primary"
          :class="{ alert: isAlerting }"
          id="display-title"
        >
          <div class="row">
            <h2 class="cap">{{ ordersTitleText }}</h2>
          </div>
        </base-card>

        <display-orders
          :queryTime="queryTime"
          :queryLocation="queryLocation"
          @order-packaged="orderPackaged"
        ></display-orders>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrdersHeader from './OrdersHeader';
import DisplayOrders from './DisplayOrders';
import { snakeCase, capitalCase } from 'change-case';

export default {
  components: {
    OrdersHeader,
    DisplayOrders,
  },

  data() {
    return {
      queryStatus: '',
      queryLocation: '',
      queryLocationId: null,
      queryTime: 'today',
      fetchOrdersInterval: '',
      isLocationInit: false,
      isRefreshOrders: false,
      isAlerting: false,
      submittedOrdersOnLoadCount: 0,
      initialSubmittedOrdersIds: [],
      audio: null,
      audioUrl: 'https://cdn.pixabay.com/download/audio/2022/01/18/audio_b9ed82666f.mp3?filename=friend-request-14878.mp3',
      alertAudioInterval: '',
      packagingTimerInterval: '',
      packagingTimerMinutes: 15,
    };
  },

  computed: {
    ...mapGetters(['statusList', 'todayOrders']),
    titleStatus() {
      return this.queryStatus !== 'all status' ? capitalCase(this.queryStatus) : '';
    },
    ordersTitleText() {
      return this.queryTime === 'today'
        ? `Open ${this.titleStatus} Orders`
        : `Archived ${this.titleStatus} Orders`;
    },
    queryParamString() {
      let string = ''
      if (this.queryTime === 'today') {
        string = `?time=${this.queryTime}`;
        // otherwise pagination is first param
      }
      if (this.queryStatus !== '') {
        string += `&status=${snakeCase(this.queryStatus)}`;
      }
      if (!this.queryLocation.includes('all')) {
        string += `&location=${this.queryLocationId}`;
      }
      this.$store.commit('setQueryParamsString', string)
      return string
    },
  },
  methods: {
    updateQueryLocation(location) {
      this.queryLocation = location[0];
      this.queryLocationId = location[1];
      this.getOrders();
      this.isLocationInit = true
    },
    updateQueryStatus(status) {
      this.queryStatus = status;
      this.getOrders();
    },
    updateQueryTime(time) {
      this.queryTime = time;
      if (this.queryTime === 'today') {
        this.getOrders();
        this.initFetchOrdersInterval()
      } else {
        clearInterval(this.fetchOrdersInterval);
      }
      this.getOrders();
    },
    initFetchOrdersInterval() {
      if (this.queryTime === 'today') {
        this.fetchOrdersInterval = setInterval(() => {
          this.isRefreshOrders = true
          this.getOrders();
        }, 60000);
        // }, 10000); // (testing) 10 seconds
      }
    },
    runAlert() {
      this.isAlerting = true
      if (!this.audio) {
        this.audio = new Audio(this.audioUrl);
      }
      this.audio.play();
      this.alertAudioInterval = setInterval(() => {
        this.audio.play();
      }, 3000);
    },
    stopAlert() {
      this.isAlerting = false
      clearInterval(this.alertAudioInterval);
      clearInterval(this.packagingTimerInterval);
      if (this.audio) {  // added this check
        this.audio.pause();  // stop the audio
        this.audio.currentTime = 0;  // reset the audio time
      }
    },
    setPackagingTimer() {
      if (this.packagingTimerInterval) {
        clearInterval(this.packagingTimerInterval);
      }
      // if the submitted order is not packaged within 15 minutes of canceling the alert, run alert again
      // TODO: clear packaging timer if the order is packaged
      this.packagingTimerInterval = setInterval(() => {
        console.log('packaging timer firing run alert')
        if (!this.isAlerting) { // Only run the alert if it's not already alerting
          this.runAlert();
        } else {
          console.log('is already alerting')
        }
      }, 60000 * this.packagingTimerMinutes
        // }, 20000  // (testing) 
      );
    },
    orderPackaged() {
      // NOT HAPPENING!
      console.log('order packaged')
      clearInterval(this.packagingTimerInterval)
    },
    async getOrders() {
      if (this.isLocationInit) {
        try {
          const orders = await this.$store.dispatch('getOrders', [this.queryParamString, this.queryTime]);
          const submittedOrders = orders.filter(order => order.status === 'submitted')
          if (!this.isRefreshOrders && this.queryTime === 'today') {
            // console.log('INITIAL LOAD')
            // console.log('submittedOrders: ', submittedOrders)
            // list initial submitted order ids in array
            submittedOrders.forEach(order => this.initialSubmittedOrdersIds.push(order.id))
          } else {
            // console.log('REFRESH LOAD')
            // console.log('submittedOrders: ', submittedOrders)
            // if refreshed submitted orders id is not in initial ids array, add it and run alert
            submittedOrders.forEach(order => {
              // console.log('this.initialSubmittedOrdersIds: ', this.initialSubmittedOrdersIds)
              // console.log('orderId: ', order.id)
              const idIndex = this.initialSubmittedOrdersIds.indexOf(order.id)
              // console.log('idIndex: ', idIndex)
              if (idIndex === -1) {
                this.initialSubmittedOrdersIds.push(order.id)
                this.runAlert()
              }
            })
            // remove any initial ids that are no longer in refreshed orders
            this.initialSubmittedOrdersIds.forEach((id, i) => {
              let match = submittedOrders.filter(order => order.id === id)
              if (match.length === 0) {
                this.initialSubmittedOrdersIds.splice(i, 1)
              }
            })
          }
        } catch (error) {
          this.error = error.message || 'Failed to fetch orders';
        }
      }
    },
    ifIsSubmittedOrderSetPackagingTimer() {
      const submittedOrders = this.todayOrders.filter(order => order.status === 'submitted');
      if (submittedOrders.length > 0) {
        console.log(`submitted order detected, setting packaging timer for ${this.packagingTimerMinutes} minutes.`)
        this.setPackagingTimer()
      } else {
        console.log('no submitted orders detected, clearing packaging timer')
        clearInterval(this.packagingTimerInterval);
      }
    },
    handleMouseMove() {
      this.stopAlert()
      this.ifIsSubmittedOrderSetPackagingTimer()
    },
  },
  mounted() {
    setTimeout(() => {
      this.ifIsSubmittedOrderSetPackagingTimer()
    }, 1000);

    this.initFetchOrdersInterval()
    // set page title
    document.title = 'WOD Orders';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);

    window.addEventListener('mousemove', this.handleMouseMove);
  },
  // beforeUnmount() {
  //   this.stopAlert()
  //   clearInterval(this.fetchOrdersInterval);
  //   clearInterval(this.packagingTimerInterval);
  // },
  unmounted() {
    this.stopAlert()
    window.removeEventListener('mousemove', this.handleMouseMove);
    clearInterval(this.fetchOrdersInterval);
    clearInterval(this.packagingTimerInterval);
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--primary-background);
  background-color: var(--accent-background);
  text-align: center;
  position: initial;
}

.alert {
  // background-color: ;
  animation: blinkingBackground 2s infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: #10c018;
  }

  25% {
    background-color: #1056c0;
  }

  50% {
    background-color: #ef0a1a;
  }

  75% {
    background-color: #254878;
  }

  100% {
    background-color: #04a1d5;
  }
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.outer-show-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner-show-container {
  width: 960px;
}

.row {
  display: flex;
  justify-content: center;
}

:deep(.base-card) {
  max-width: 100% !important;
}

select {
  height: 20px;
}

label {
  margin: 0 5px;
}

#display-title {
  margin-top: 30px;
}

@media screen and (max-width: 650px) {
  .inner-show-container {
    width: 97vw;
  }

  :deep(.base-card) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
