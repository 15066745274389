export default {
  // orders
  setTodayOrders(state, payload) {
    state.todayOrders = payload
  },
  setArchiveOrders(state, payload) {
    state.archiveOrders = payload
  },
  setOrderItems(state, payload) {
    state.orderItems = payload.orderItems;
  },
  setQueryParamsString(state, payload) {
    state.queryParamsString = payload
  },
  // pagination for archived orders
  setTotalOrdersCount(state, payload) {
    state.totalOrdersCount = payload
  },
  setCurrentOrdersPaginationPage(state, payload) {
    state.currentOrdersPaginationPage = payload
  },
  // supplements
  setFlowerPricePoints(state, payload) {
    state.flowerPricePoints = payload.flowerPricePoints
  },
  setLocations(state, payload) {
    const locations = {}
    payload.forEach(location => {
      const id = location.id
      locations[id] = location
    })
    locations.get = (prop, id) => {
      return locations[id][prop]
    }
    state.locations = locations
    // console.log('locations: ', state.locations)
  },
};
