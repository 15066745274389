<template>
  <header>
    <div id="header-main">
      <router-link to="/dashboard">
        <img
          id="logo"
          src="../../../assets/logo.png"
        />
      </router-link>
      <h1>

        <span id="wod"> WOD </span>
        {{ storeLocation !== 'all' ? storeLocation : '' }}
        <span class="thin"> {{ pageCategory }} Specials </span>
      </h1>
      <form>
        <select
          id="location-selector"
          class="selector"
          name="location-selector"
          v-bind:value="storeLocation"
          @change="changeStoreLocation"
        >
          <option value="all">All Locations</option>
          <option value="Beaverton">Beaverton</option>
          <option value="Cedar Mill">Cedar Mill</option>
          <option value="Hillsboro">Hillsboro</option>
          <option value="Newberg">Newberg</option>
          <option value="Sherwood">Sherwood</option>
        </select>
        <select
          id="page-category"
          class="selector"
          name="page-category"
          v-bind:value="pageCategory"
          @change="changePageCategory"
        >
          <option value="Current">Current Specials</option>
          <option value="Upcoming">Upcoming Specials</option>
          <option value="Past">Past Specials</option>
        </select>
      </form>

    </div>
    <UserNav />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import UserNav from '../../UI/UserNav.vue';

export default {
  components: {
    UserNav,
  },
  computed: { ...mapGetters(['pageCategory', 'storeLocation']) },
  methods: {
    changePageCategory(e) {
      this.$store.dispatch({
        type: 'changePageCategory',
        value: e.target.value,
      });
    },
    changeStoreLocation(e) {
      this.$store.dispatch({
        type: 'changeStoreLocation',
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/components/UI/_themes.scss';

#header-main {
  display: flex;
  justify-content: flex-start;

  h1 {
    margin-top: 14px;
  }

  .selector {
    margin-top: 22px;
  }
}

header {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 5rem;
  background-color: var(--accent-background);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

header h1 {
  color: white;
  margin: 0 10px;
}

a {
  text-decoration: none;
}

.thin {
  font-weight: 100;
}

#logo {
  width: 60px;
}

@media screen and (max-width: 1150px) {
  #wod {
    display: none;
  }
}

@media screen and (max-width: 1050px) {
  header {
    height: 4rem;
  }

  header h1 {
    display: none;
  }

  img {
    margin-left: 3px;
  }

}

@media screen and (max-width: 800px) {
  header {
    justify-content: flex-start;
    padding-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  :deep(#username) {
    display: none;
  }
}

@media screen and (max-width: 490px) {
  header {
    height: 80px;
    padding-left: 10px;

    img {
      margin-right: 11px;
    }

  }

  #header-main {
    .selector {
      margin-top: 5px !important;
    }
  }

  form {
    display: flex;
    flex-direction: column;

  }

}
</style>
