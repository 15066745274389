import { snakeCase } from "change-case";
const apiUrl = process.env.VUE_APP_DEV_API_URL

function processOrderItems(order) {
  const items = [];
  order.order_items.forEach(item => {
    items.push({
      count: item.count,
      pricepoint: JSON.parse(item.flower_price_point),
      grams: item.grams,
      id: item.id,
      orderId: item.order,
      priceEach: item.price_each,
      productName: item.product_name,
      productType: item.product_type,
      user: item.user,
      vendorName: item.vendor_name,
      locationId: item.location,
    });
  });
  function orderStateLocalTime(orderState) {
    if (orderState === null) {
      return ''
    } else {
      return new Date(orderState).toLocaleTimeString()
    }
  }
  const formattedOrder = {
    id: order.id,
    username: order.username,
    userRole: order.user_role,
    userPhone: order.user_phone,
    userEmail: order.user_email,
    submissionDate: order.submission_date,
    submissionDateLocal: new Date(order.submission_date).toLocaleDateString(),
    submissionTimeLocal: orderStateLocalTime(order.submission_date),
    packagedDate: order.packaged_date,
    packagedTimeLocal: orderStateLocalTime(order.packaged_date),
    finishedDate: order.finished_date,
    finishedTimeLocal: orderStateLocalTime(order.finished_date),
    locationId: order.location,
    status: order.status,
    isMedical: order.is_medical,
    isUserBlacklisted: order.is_user_blacklisted,
    discount: false,
    items,
  };
  if (order.order_discount !== null) {
    formattedOrder.discount = {
      id: order.order_discount.id,
      amount: order.order_discount.amount,
      type: order.order_discount.discount_type,
      startDate: order.order_discount.start_date,
      endDate: order.order_discount.end_date,
      minimumPurchase: order.order_discount.minimum_purchase,
      name: order.order_discount.name,
    }
  }
  return formattedOrder;
}

export default {
  checkLocalLocations(context) {
    // occurs on create, not on fetch
    // const locationsJson = localStorage.getItem('locations');
    // const locations = JSON.parse(locationsJson)
    // if (locations) {
    //   context.commit('setLocations', locations);
    // } else {
    //   context.dispatch('getLocations')
    // }
    context.dispatch('getLocations')
  },
  async getLocations(context) {
    // console.log('fired get locations')
    const response = await fetch(`${apiUrl}/orders/locations/`)
    if (response.ok) {
      const res = await response.json()
      localStorage.setItem('locations', JSON.stringify(res));
      context.commit('setLocations', res)
    } else {
      // console.log('get location response NOT OK')
      // console.log('response: ', response)
    }
    return response
  },
  async getOrders(context, payload) {
    const queryParamsString = payload[0]
    const time = payload[1]
    let pagination = ''
    if (time === 'archive') {
      const page = context.rootGetters.currentOrdersPaginationPage
      pagination = `?page=${page}`
    }
    const response = await fetch(`${apiUrl}/orders/${time}_orders/${pagination}${queryParamsString}`, {
      headers: { Authorization: `Bearer ${context.rootGetters.token}` }
    })
    if (response.ok) {
      // console.log('qps: ', queryParamsString)
      // console.log('time: ', time)
      const res = await response.json()
      // const formattedRes = [];
      let orders = res
      if (time === 'archive') {
        context.commit('setTotalOrdersCount', res.count)
        orders = res.results
      }
      const formattedOrders = orders.map(processOrderItems)
      if (time === 'today') {
        context.commit('setTodayOrders', formattedOrders);
        return formattedOrders
      } else if (time === 'archive') {
        context.commit('setArchiveOrders', formattedOrders);
      } else {
        console.error('time value unrecognized')
      }
    } else {
      console.error(response);
      console.error('error in getOrders in actions.js');
      this.isLoading = false;
    }
    return 'done'
  },
  async patchOrderStatus(context, payload) {
    // console.log('patching order status to: ', payload.orderStatus)
    const response = await fetch(`${apiUrl}/orders/orders/${payload.orderId}/`, {
      method: 'PATCH',
      accept: 'application/json',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${context.rootGetters.token}`,
      },
      body: JSON.stringify({ status: snakeCase(payload.orderStatus) })
    })
    if (response.ok) {
      const res = await response.json()
      return res
    } else {
      console.error(response);
      console.error('failed to patch order status');
    }
  }
}

