import { createRouter, createWebHistory } from 'vue-router';
import store from './store/index';
import { trackRouter } from 'vue-gtag-next';

import SpecialsPage from './components/pages/SpecialsPage/SpecialsPage';
import StrainsPage from './components/pages/StrainsPage/StrainsPage';
import OrdersPage from './components/pages/OrdersPage/OrdersPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import DashPage from './components/pages/DashPage/DashPage';
import AnalyticsPage from './components/pages/AnalyticsPage/AnalyticsPage';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next({ path: '/dashboard' });
        } else {
          next({ path: '/login' });
        }
      }
    },
    { name: 'login', path: '/login', component: LoginPage },
    {
      name: 'dashboard',
      path: '/dashboard',
      component: DashPage,
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next();
        } else {
          next({ path: '/login' });
        }
      }
    },
    {
      name: 'orders',
      path: '/orders',
      component: OrdersPage,
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next();
        } else {
          next({ path: '/login' });
        }
      }
    },
    {
      name: 'specials',
      path: '/specials',
      components: {
        body: SpecialsPage
      },
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next();
        } else {
          next({ path: '/login' });
        }
      }
    },
    {
      name: 'strains',
      path: '/strains',
      components: {
        body: StrainsPage
      },
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next();
        } else {
          next({ path: '/login' });
        }
      }
    },
    {
      name: 'analytics',
      path: '/analytics',
      components: {
        body: AnalyticsPage
      },
      beforeEnter(_, _2, next) {
        if (store.getters.isStaff) {
          next();
        } else {
          next({ path: '/login' });
        }
      }
    },
    { path: '/:pathMatch(.*)*', redirect: 'login' }
  ]
});

trackRouter(router);

export default router;
