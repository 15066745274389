<template>
  <ul class="pagination">
    <li class="pagination-item">
      <button
        type="button"
        class="light-button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
      >
        First
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        class="light-button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >
        Previous
      </button>
    </li>

    <!-- Numbered Buttons Start -->
    <li
      v-for="page in pages"
      class="pagination-item"
      :key="page.name"
    >
      <button
        type="button"
        class="light-button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li>

    <!-- ... -->

    <!-- Numbered Buttons End -->

    <li class="pagination-item">
      <button
        type="button"
        class="light-button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
      >
        Next
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        class="light-button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
      >
        Last
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      maxVisibleButtons: 3,
    }
  },
  computed: {
    ...mapGetters(["totalOrderPages", "ordersPerPage", "currentOrdersPaginationPage", "queryParamsString"]),
    startPage() {
      // When on the first page
      if (this.currentOrdersPaginationPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentOrdersPaginationPage === this.totalOrderPages) {
        if (this.totalOrderPages - this.maxVisibleButtons > 0) {

          return this.totalOrderPages - this.maxVisibleButtons + 1;
        } else {
          return 1
        }
      }

      // When inbetween
      return this.currentOrdersPaginationPage - 1;
    },
    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalOrderPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentOrdersPaginationPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentOrdersPaginationPage === 1;
    },
    isInLastPage() {
      return this.currentOrdersPaginationPage === this.totalOrderPages;
    },
  },
  methods: {
    onPageChange(page) {
      this.$store.commit('setCurrentOrdersPaginationPage', page)
      this.$store.dispatch('getOrders', [this.queryParamsString, 'archive'])
    },
    onClickFirstPage() {
      this.onPageChange(1);
    },
    onClickPreviousPage() {
      this.onPageChange(this.currentOrdersPaginationPage - 1);
    },
    onClickPage(page) {
      this.onPageChange(page);
    },
    onClickNextPage() {
      this.onPageChange(this.currentOrdersPaginationPage + 1);
    },
    onClickLastPage() {
      this.onPageChange(this.totalOrderPages);
    },
    isPageActive(page) {
      return this.currentOrdersPaginationPage === page;
    }
  }
};
</script>

<style scoped>
.pagination {
  list-style-type: none;
  padding: 0;
  margin: 15px;
}

.pagination-item {
  display: inline-block;
  padding: 1px;
}

.active {
  background-color: var(--accent-background);
  color: var(--primary-background);
}
</style>