import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state() {
    return {
      specialsTimeFilter: 'Current',
      specialsArr: [],
      storeLocation: 'all'
    };
  },
  mutations,
  actions,
  getters
};
