<template>
  <SpecialsHeader />

  <error-modal
    v-if="inputIsInvalid"
    @confirm-error="toggleErrorModal"
  ></error-modal>

  <div class="body-container">
    <div class="outer-add-container">
      <div class="inner-add-container">
        <!-- Add New Special Title Element -->
        <base-card
          class="title title-add"
          :class="[editMode ? 'edit-light' : '']"
        >
          <h2>
            {{ editMode ? 'Edit' : 'Add New' }}
            {{ storeLocation !== 'all' ? storeLocation : '' }} Special
          </h2>
        </base-card>

        <!-- Add New Special Form Component -->
        <add-special
          :storeLocation="storeLocation"
          :editMode="editMode"
          :editSpecialValues="editSpecialValues"
          @fetch-specials="fetchSpecials"
          @error-modal="toggleErrorModal"
          @edit-off="editModeOff"
        ></add-special>
      </div>
    </div>
    <div class="outer-show-container">
      <div class="inner-show-container">
        <!-- Display Specials Title Element -->
        <base-card
          class="title"
          id="display-title"
        >
          <div class="row">
            <h2>
              {{ pageCategory }}
              {{ storeLocation !== 'all' ? storeLocation : '' }} Specials
            </h2>
            <div class="vert-center">
              <div class="row">
                <select
                  id="sortBy"
                  name="sortBy"
                  v-bind:value="sortBy"
                  @change="updateSortBy"
                >
                  <option value="from">Sorted By "From"</option>
                  <option value="until">Sorted By "Until"</option>
                  <option value="created">Sorted By "Created"</option>
                </select>
                <button
                  class="ascDsc"
                  @click="ascDsc"
                >
                  <i
                    v-if="asc"
                    class="fas fa-arrow-up"
                  ></i>
                  <i
                    v-else
                    class="fas fa-arrow-down"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </base-card>

        <!-- Display Specials Component -->
        <display-specials
          :editMode="editMode"
          :editSpecialValues="editSpecialValues"
          :sortBy="sortBy"
          :asc="asc"
          @fetch-specials="fetchSpecials"
          @edit-special="toggleEditMode"
        ></display-specials>
      </div>
    </div>
  </div>
</template>

<script>
import SpecialsHeader from './SpecialsHeader';
import ErrorModal from '../ErrorModal';
import DisplaySpecials from './DisplaySpecials';
import AddSpecial from './AddSpecial';
import { mapGetters } from 'vuex';

export default {
  components: {
    SpecialsHeader,
    ErrorModal,
    DisplaySpecials,
    AddSpecial,
  },
  data() {
    return {
      inputIsInvalid: false,
      editMode: false,
      editSpecialValues: {},
      sortBy: 'from',
      asc: false,
    };
  },
  computed: {
    ...mapGetters([
      'pageCategory',
      'storeLocation',
      'specials',
    ]),
  },
  provide() {
    return {
      addSpecial: this.addSpecial,
    };
  },
  methods: {
    submitEdits() {
      this.toggleEditModal();
    },
    toggleErrorModal() {
      this.inputIsInvalid = !this.inputIsInvalid;
    },
    toggleEditMode(specialObj) {
      this.editMode = !this.editMode;
      this.editSpecialValues = specialObj;
    },
    editModeOff() {
      this.editMode = false;
    },
    ascDsc() {
      this.asc = !this.asc;
    },
    fetchSpecials() {
      this.$store.dispatch('fetchSpecials');
    },
    updateSortBy(e) {
      this.sortBy = e.target.value;
    },
  },

  mounted() {
    // set page title
    document.title = 'WOD Specials';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);
  },
};
</script>

<style lang="scss" scoped>
:deep(.base-card) {
  width: 608px !important;
}

.title {
  position: sticky;
  top: 65px;
  color: var(--primary-background);
  background-color: var(--accent-background);
  text-align: center;
}

.body-container {
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: center;
}

select {
  height: 20px;
}

label {
  margin: 0 5px;
}

.vert-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ascDsc {
  background-color: var(--primary-background);
  color: var(--accent-background);
  border-radius: 5px;
}

@media (min-width: 1280px) {
  .inner-add-container {
    position: sticky;
    top: 113px;
  }
}

/* move to single column*/
@media screen and (max-width: 1280px) {
  .title {
    position: initial;
    width: 95vw;
  }

  .body-container {
    flex-direction: column;
  }

  .outer-add-container,
  .show-container {
    // display: flex;
    // justify-content: center;
  }

  #display-title {
    margin-top: 0;
  }

  .outer-add-container,
  .outer-show-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  :deep(.base-card) {
    width: 97vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>