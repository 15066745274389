import { createStore } from 'vuex';
import authModule from './auth/index';
import ordersModule from './orders/index';
import specialsModule from './specials/index';
import strainsModule from './strains/index';

const store = createStore({
  modules: {
    auth: authModule,
    orders: ordersModule,
    specials: specialsModule,
    strains: strainsModule
  },
  state() {
    return {};
  },
  mutations: {},
  actions: {},
  getters: {}
});
export default store;
