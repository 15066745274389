<template>
  <div
    class="base-card"
    :class="{ strobe: isSubmitted }"
    :style="{ border: border }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  // props: [
  //   'borderColor', {
  //     type: String
  //   },
  //   'isSubmitted', {
  //     type: Boolean,
  //     default: false
  //   }
  // ],
  props: ['borderColor', 'isSubmitted'],
  computed: {
    border() {
      return `2px solid ${this.borderColor}`
    }
  }
}
</script>

<style scoped>
.base-card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem 20px;
  max-width: 40rem;
}

.border-color {
  border-color: var(--primary-background);
}

.strobe {
  background-color: rgba(255, 165, 0, .1);
  animation: blink 1s infinite;
}

@keyframes blink {
  25% {
    background-color: var(--app-background-color);
  }
}
</style>