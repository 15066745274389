import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state() {
    return {
      todayOrders: [],
      archiveOrders: [],
      orderItems: [],
      queryParamsString: '',
      // pagination for archive orders
      ordersPerPage: 10,
      currentOrdersPaginationPage: 1,
      totalOrdersCount: 0,
      // supplements
      useProductionOrdersApi: false,
      flowerPricePoints: [],
      locations: {},
      locationsByName: {},
      defaultLocations: ['beaverton', 'cedar_mill', 'hillsboro', 'newberg', 'sherwood'],
      statusList: [
        {
          value: 'all_status',
          label: 'All Status'
        },
        {
          value: 'submitted',
          label: 'Submitted'
        },
        {
          value: 'packaged',
          label: 'Packaged'
        },
        {
          value: 'sold',
          label: 'Sold'
        },
        {
          value: 'store_canceled',
          label: 'Store Canceled'
        },
        {
          value: 'customer_canceled',
          label: 'Customer Canceled'
        },
      ],
    };
  },
  mutations,
  actions,
  getters
};
