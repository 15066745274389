<template>
  <OrderModal
    v-if="isDisplayOrderModal"
    :order="modalOrder"
    :queryTime="modalQueryTime"
    @close-modal="closeOrderModal"
    @order-packaged="$emit('order-packaged')"
  />
  <div class="orders-column">
    <ul>
      <OrderRow
        ref="renderedOrders"
        v-for="order in sortedOrders"
        :key="order.id"
        :order="order"
        :queryTime="queryTime"
        :queryLocation="queryLocation"
        :statusList="statusOptionsList"
        @open-order-modal="openOrderModal"
      ></OrderRow>
    </ul>
    <div id="pagination-card-container">
      <base-card
        v-if="queryTime === 'archive'"
        id="pagination-card"
      >
        <Pagination />
      </base-card>
    </div>
  </div>
</template>

<script>
import OrderRow from './OrderRow.vue';
import { mapGetters } from 'vuex';
import Pagination from './Pagination.vue';
import OrderModal from '../OrderModal.vue';

export default {
  components: {
    OrderRow,
    Pagination,
    OrderModal
  },
  emits: ['order-packaged'],
  props: ['queryTime', 'queryLocation'],
  data() {
    return {
      isLoading: false,
      error: null,
      isDisplayOrderModal: false,
      modalOrder: {},
      modalQueryTime: {},
      // viewMode: 'today',
    };
  },

  computed: {
    ...mapGetters(['todayOrders', 'archiveOrders', 'statusList']),
    orders() {
      return this.isQueryTimeToday ? this.todayOrders : this.archiveOrders
    },
    statusOptionsList() {
      console.log('statusOptionsList', this.statusList)
      return this.statusList.slice(1);
    },
    sortedOrders() {
      return this.isQueryTimeToday ? this.getSortedOrders() : this.orders;
    },
    isQueryTimeToday() {
      return this.queryTime === 'today';
    },
  },

  methods: {
    getSortedOrders() {
      return this.statusOptionsList.flatMap(status => this.getValidOrdersWithStatus(status.value));
    },
    getValidOrdersWithStatus(status) {
      return this.orders.filter(order => order.status === status && !order.isUserBlacklisted);
    },
    openOrderModal(data) {
      this.modalOrder = data.order
      this.modalQueryTime = data.queryTime
      this.isDisplayOrderModal = true
    },
    closeOrderModal() {
      this.modalOrder = {}
      this.modalQueryTime = {}
      this.isDisplayOrderModal = false
    },
  },

  watch: {},

  created() { },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.orders-column {
  width: 100%;
  margin: 0;
}

#pagination-card-container {
  display: flex;
  justify-content: center;
}

#pagination-card {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 360px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 100%;
}

li.card {
  max-width: 100%;
}
</style>
