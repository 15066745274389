<template>
  <div class="form-column">
    <base-card>
      <form @submit.prevent="">
        <label for="strain">Strain</label>
        <div class="form-control">
          <input
            id="strain"
            name="strain"
            type="text"
            autofocus
            autocomplete="off"
            ref="strainInput"
            v-model.trim="strainInput"
          />
        </div>

        <label for="feel">Feel</label>
        <div class="form-control">

          <input
            type="radio"
            name="feel"
            id="uplifting"
            value="Uplifting"
            v-model="feelInput"
          />
          <label
            for="uplifting"
            id="upliftingColor"
          >Uplifting</label>

          <input
            type="radio"
            name="feel"
            id="balanced"
            value="Balanced"
            v-model="feelInput"
          />
          <label
            for="balanced"
            id="balancedColor"
          >Balanced</label>

          <input
            type="radio"
            name="feel"
            id="relaxing"
            value="Relaxing"
            v-model="feelInput"
          />
          <label
            for="relaxing"
            id="relaxingColor"
          >Relaxing</label>

          <input
            type="radio"
            name="feel"
            id="cbd"
            value="CBD"
            v-model="feelInput"
          />
          <label
            for="cbd"
            id="cbdColor"
          >CBD</label>
        </div>

        <div class="button-container">
          <base-button @click="addStrain"> {{ buttonText }} </base-button>
        </div>
      </form>
    </base-card>
  </div>
</template>

<script>
export default {
  props: ['userIsNotTourist', 'exactMatch'],
  emits: ['strain-search', 'error-modal', 'add-strain'],
  data() {
    return {
      strainInput: '',
      feelInput: 'Uplifting',
    };
  },
  computed: {
    addOrUpdate() {
      return this.exactMatch.exists ? 'Update' : 'Add'
    },
    buttonText() {
      return this.strainInput !== ''
        ? `${this.addOrUpdate} ${this.strainInput} (${this.feelInput})`
        : 'Add Strain';
    },
  },
  methods: {
    addStrain() {
      this.$emit('add-strain', { strain: this.strainInput, feel: this.feelInput })
      this.strainInput = '';
      this.$refs.strainInput.focus();
    },
    confirmError() {
      this.inputIsInvalid = false;
    },
  },
  watch: {
    strainInput() {
      this.$emit('strain-search', this.strainInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
}

.form-column {
  width: 100%;
  margin: 0;
}

h2 {
  margin-block: 0.2em;
}

.form-control {
  margin: 0.5rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

label {
  margin-right: 10px;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  text-align: center;
}

input {
  display: block;
  font: inherit;
  padding: 0.15rem;
  border: 1px solid #ccc;
  resize: none;
}

input#strain {
  width: 285px;
}

input:focus {
  outline: none;
  border-color: royalblue;
  background-color: #d4ffda;
}

.button-container {
  display: flex;
  justify-content: center;
}

button:hover {
  background-color: #d4ffda;
  color: royalblue;
}

#upliftingColor {
  color: rgb(197, 47, 47);
}

#balancedColor {
  color: rgb(72, 137, 197);
}

#relaxingColor {
  color: rgb(123, 53, 177);
}

#cbdColor {
  color: #12da22;
}

@media screen and (max-width: 420px) {
  .form-control {
    justify-content: space-between;
    margin: 0.5rem 0rem;
  }

  label {
    margin-right: 0;
  }

  input#strain {
    margin-left: 6px;
    width: 100%;
  }
}
</style>
