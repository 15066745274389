<template>
  <SimpleHeader>Strains</SimpleHeader>

  <error-modal
    v-if="inputIsInvalid"
    @confirm-error="toggleErrorModal"
  ></error-modal>

  <div class="body-container">
    <div class="outer-add-container">
      <div class="inner-add-container">
        <base-card class="title">
          <h2>Add Strain Feel</h2>
        </base-card>
        <add-strain
          :userIsNotTourist="userIsNotTourist"
          :exactMatch="exactMatch"
          @add-strain="addStrain"
          @strain-search="strainSearch"
        ></add-strain>
      </div>
    </div>
    <div class="outer-show-container">
      <div class="inner-show-container">
        <base-card
          class="title"
          id="display-title"
        >
          <div class="row">
            <h2>Strain Matches ({{ strainCount }})</h2>
          </div>
        </base-card>

        <display-strains
          :search="search"
          @delete-strain="deleteStrain"
          @strain-count="updateStrainCount"
          @exact-match="updateExactMatch"
        ></display-strains>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleHeader from '../../UI/SimpleHeader.vue';
import ErrorModal from '../ErrorModal';
import AddStrain from './AddStrain';
import DisplayStrains from './DisplayStrains';

export default {
  components: {
    SimpleHeader,
    ErrorModal,
    AddStrain,
    DisplayStrains,
  },

  data() {
    return {
      search: '',
      inputIsInvalid: false,
      strainCount: '',
      exactMatch: { exists: false },
    };
  },

  computed: {
    ...mapGetters(['strainsUrl', 'userIsNotTourist']),
  },

  methods: {
    strainSearch(name) {
      this.search = name;
    },
    updateStrainCount(count) {
      this.strainCount = count;
    },
    updateExactMatch(exactMatch) {
      this.exactMatch = exactMatch
    },
    async addStrain(input) {
      console.log('addStrain()...')
      console.log('input: ', input)
      if (this.exactMatch.exists) {
        await this.deleteStrain(this.exactMatch.id)
      }
      setTimeout(() => {
        this.postStrain(input)
      }, 200);
    },
    async postStrain(input) {
      console.log('postStrain()...')
      if (input.strain === '') {
        // this.$emit('error-modal');
        return;
      }
      try {
        await this.$store.dispatch('postStrain', {
          strain: input.strain,
          feel: input.feel,
        });
        // moved to addStrain.vue
        // this.strainInput = '';
        // this.$refs.strainInput.focus();
      } catch (error) {
        this.error = error.message || 'Failed to post strain';
      }
    },
    async deleteStrain(id) {
      if (this.userIsNotTourist) {
        try {
          await this.$store.dispatch('deleteStrain', { id: id });
        } catch (error) {
          this.error = error.message || 'Failed to delete strain';
        }
      }
    },
  },

  mounted() {
    // set page title
    document.title = 'WOD Strains';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);
  },
};
</script>

<style lang="scss" scoped>
:deep(.base-card) {
  width: 608px !important;
}

.title {
  color: var(--primary-background);
  background-color: var(--accent-background);
  text-align: center;
}

.body-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 1280px) {
  .inner-add-container {
    position: sticky;
    top: 113px;
  }
}

.row {
  display: flex;
  justify-content: center;
}

select {
  height: 20px;
}

label {
  margin: 0 5px;
}


/* move to single column*/
@media screen and (max-width: 1280px) {
  .title {
    position: initial;
    width: 95vw;
  }

  .body-container {
    flex-direction: column;
  }

  #display-title {
    margin-top: 0;
  }

  .outer-add-container,
  .outer-show-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  :deep(.base-card) {
    width: 97vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
