<template>
  <div class="body">
    <button @click="this.$router.push('/dashboard')">Home</button>
    <button @click="getTrackerStats">Refresh</button>
    <vue-json-pretty
      :show-double-quotes="false"
      class="json"
      :data="stats"
    />

  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
const apiUrl = process.env.VUE_APP_DEV_API_URL

export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      stats: {},
    };
  },
  computed: {

  },
  methods: {

    async getTrackerStats() {
      const response = await fetch(`${apiUrl}/analytics/tracker-stats/`)
      if (response.ok) {
        const res = await response.json()
        this.stats = res
        console.log('stats: ', res)
      } else {
        console.log('response: ', response)
      }
      return response
    },
  },
  created() {
    this.getTrackerStats()
  },
  mounted() {
    // set page title
    document.title = 'WOD Stats';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);
  },
};
</script>

<style scoped></style>