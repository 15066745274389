<template>
  <teleport to="body">
    <div @click="$emit('close')"></div>
    <dialog open>
      <header>
        <slot name="header">
          <h2>{{ title }}</h2>
        </slot>
        <a>
          <h2
            id="x"
            @click="$emit('close')"
          >X</h2>
        </a>
      </header>
      <section>
        <slot></slot>
      </section>
      <menu>
        <slot name="actions">
          <base-button @click="$emit('close')"></base-button>
        </slot>
      </menu>
    </dialog>
  </teleport>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: { BaseButton },
  props: {
    title: {
      type: String,
      required: false
    }
  },
  emits: ['close']
}
</script>

<style lang="scss" scoped>
@import "src/components/UI/_themes.scss";

div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  background-color: var(--accent-background);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

header h2 {
  margin: 0;
  display: inline;
}

#x {
  font-weight: lighter;
}

#x:hover {
  font-weight: bold;
  cursor: pointer;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }

}

@media (max-width: 420px) {
  dialog {
    position: absolute;
    top: 0;
    width: 98%;
    left: 1%;
  }
}
</style>