<template>
  <header>

    <div id="header-main">
      <router-link to="/dashboard">
        <img
          id="logo"
          src="../../../assets/logo.png"
        />
      </router-link>
      <h1 class="cap">
        <span id="wod"> WOD </span>
        {{ selectedLocationObj.name !== 'all' ? selectedLocationCapitalCase : '' }}
        <span class="thin"> Orders </span>
      </h1>

      <form @submit.prevent="">

        <v-select
          v-if="userLocationIsAll"
          class="selector"
          id="vs-location"
          :reduce="location => location.label"
          :clearable="false"
          :options="locationsList"
          v-model="selectedLocationsListItemLabel"
          @option:selected="updateSelectedLocation"
        />

        <v-select
          v-if="!userLocationIsAll"
          class="selector"
          id="vs-status"
          :clearable="false"
          :options="statusList"
          v-model="selectedStatus"
        />
        <v-select
          v-else
          class="selector"
          id="vs-status"
          :clearable="false"
          :options="statusList"
          v-model="selectedStatus"
          style="width:130px;"
        />

        <button
          id="archive-button"
          class="dark-button"
          @click="toggleViewArchive"
        > {{ viewArchiveButtonText }} </button>
        <UserNav v-if="isMobile" />
      </form>

    </div>
    <UserNav v-if="!isMobile" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import UserNav from '../../UI/UserNav.vue';
import { capitalCase } from "change-case";

export default {
  components: {
    UserNav,
  },
  emits: ['query-location', 'query-status', 'view-archive'],
  data() {
    return {
      showArchive: false,
      selectedStatus: {},
      selectedLocationObj: {},
      selectedLocationsListItemLabel: 'All Locations',
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters([
      'locations',
      'statusList',
      'role',
      'userLocation',
      'username',
    ]),
    viewArchiveButtonText() {
      return this.showArchive ? 'View Open' : 'View Archive';
    },
    selectedLocationCapitalCase() {
      return capitalCase(this.selectedLocationObj.name)
    },
    locationsList() {
      const allValues = Object.values(this.locations)
      const locations = allValues.filter(item => typeof item !== 'function')
      locations.forEach(location => {
        location.label = capitalCase(location.name)
        if (location.label === 'All') {
          location.label = 'All Locations'
        }
      })
      return locations.sort((a, b) => (a.label > b.label) ? 1 : -1)
    },
    isMobile() {
      return this.windowWidth <= 650
    },
    userLocationIsAll() {
      // console.log('this.userLocation', this.userLocation)
      return this.userLocation.snakeCase === 'all'
    },
  },
  methods: {
    initSelectedStatus() {
      this.selectedStatus = this.statusList[0]
    },
    initSelectedLocation() {
      try {
        const locationObj = this.locationsList.filter(location => {
          return location.id === this.userLocation.id
        })
        this.selectedLocationObj = locationObj[0]
      } catch (error) {
        console.error('check if user location was received from server', error)
      }
    },
    updateSelectedLocation(object) {
      this.selectedLocationObj = object
      this.emitQueryLocation()
    },
    emitQueryStatus() {
      let queryStatus = this.selectedStatus.value
      if (this.selectedStatus.value.includes('all')) {
        queryStatus = '';
      }
      this.$emit('query-status', queryStatus);
    },
    emitQueryLocation() {
      let queryLocation = this.selectedLocationObj.name
      this.$emit('query-location', [queryLocation, this.selectedLocationObj.id]);
    },
    toggleViewArchive() {
      this.showArchive = !this.showArchive;
      if (this.showArchive) {
        this.$emit('view-archive', 'archive');
      } else {
        this.$emit('view-archive', 'today');
      }
    },
  },
  watch: {
    selectedStatus() {
      // console.log('status changed')
      this.emitQueryStatus()
    }
  },
  created() {
    console.log('created')
    this.initSelectedLocation();
    this.initSelectedStatus();
    this.emitQueryLocation();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 5rem;
  background-color: var(--accent-background);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

#header-main {
  background-color: var(--accent-background);
  display: flex;
  justify-content: flex-start;

  h1 {
    margin-top: 14px;
  }

  .selector {
    margin-top: 15px;
  }

  #archive-button {
    margin-top: 15px;
    height: 36.4px;
  }
}

header h1 {
  color: white;
  margin: 0 10px;
}

form {
  display: flex;
}

.v-select {
  --vs-controls-color: var(--primary-background);
  --vs-border-color: var(--primary-background);
  --vs-border-width: 2px;

  --vs-dropdown-bg: var(--accent-background);
  --vs-dropdown-color: var(--primary-background);
  --vs-dropdown-option-color: var(--primary-background);

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
}

#vs-location {
  width: 155px;
}

#vs-status {
  // width: 205px;
  width: 190px;
}

a {
  text-decoration: none;
}

.thin {
  font-weight: 100;
}

#logo {
  width: 60px;
}

#user-nav {
  top: 25px
}

@media screen and (max-width: 1160px) {
  #wod {
    display: none;
  }
}

@media screen and (max-width: 1075px) {

  // header {
  //   height: 4rem;
  // }

  header h1 {
    display: none;
  }

  img {
    margin-left: 3px;
    margin-right: 15px;
  }

}

@media screen and (max-width: 975px) {
  header {
    justify-content: flex-start;
  }

  img {
    margin-left: 15px;
  }
}

@media screen and (max-width: 770px) {
  :deep(#username) {
    display: none;
  }

  #user-nav {
    height: 36.4px;
  }
}

@media screen and (max-width: 623px) {
  header {
    padding-left: 0px;
    display: flex;
    justify-content: center;

    form {
      display: flex;
      flex-direction: row;
      justify-content: right;
      flex-wrap: wrap;
    }

    img {
      margin-right: 5px;
      margin-left: 5px;
    }

  }

  #header-main {
    display: flex;
    justify-content: flex-start;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h1 {
      margin-top: 14px;
    }

    .selector {
      margin-top: 0px;
    }


    #archive-button {
      margin-top: 0px;
      height: 36.4px;
    }
  }

  #user-nav {
    display: flex;
    flex-direction: row;
    right: 0;
    top: 0;
    position: relative;
    justify-content: flex-end;

    h4 {
      color: red;
      margin-block: 0.5em;
      font-size: 16px;
    }

  }

  @media screen and (max-width: 415px) {
    #header-main {
      // padding-top: 30px;
    }
  }



}
</style>
