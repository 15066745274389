<template>
  <header>
    <router-link to="/dashboard">
      <img
        id="logo"
        src="../../assets/logo.png"
      />
    </router-link>
    <h1>
      <span id="wod"> WOD </span>
      <span class="thin">
        <slot></slot>
      </span>
    </h1>
    <UserNav />
  </header>
</template>

<script>
import UserNav from '/src/components/UI/UserNav.vue';
export default {
  components: {
    UserNav,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 5rem;
  background-color: var(--accent-background);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

header h1 {
  color: white;
  margin: 0 10px;
}

#user-nav {
  display: flex;
  right: 20px;
  position: absolute;

  button {
    margin-left: 10px;
  }

  h5 {
    color: white;
    margin-block: 0.5em;
  }
}

a {
  text-decoration: none;
}

.thin {
  font-weight: 100;
}

#logo {
  width: 60px;
}

/* Tablet */
@media screen and (max-width: 760px) {
  header {
    padding-left: 20px;
    justify-content: left;
  }
}

/* Large Phone */
@media screen and (max-width: 560px) {
  #wod {
    display: none;
  }
}

/* Phone */
@media screen and (max-width: 500px) {
  :deep(#username) {
    display: none;
  }
}
</style>
