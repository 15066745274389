export default {
  // Orders
  todayOrders(state) {
    console.log('todayOrders', state.todayOrders)
    return state.todayOrders;
  },
  archiveOrders(state) {
    return state.archiveOrders;
  },
  orderItems(state) {
    return state.orderItems;
  },
  queryParamsString(state) {
    return state.queryParamsString
  },
  // Order supplements
  flowerPricePoints(state) {
    return state.flowerPricePoints
  },
  statusList(state) {
    return state.statusList;
  },
  locations(state) {
    return state.locations;
  },
  defaultLocations(state) {
    return state.defaultLocations
  },
  // Order pagination
  totalOrdersCount(state) {
    return state.totalOrdersCount
  },
  ordersPerPage(state) {
    return state.ordersPerPage
  },
  totalOrderPages(state) {
    return Math.ceil(state.totalOrdersCount / state.ordersPerPage)
  },
  currentOrdersPaginationPage(state) {
    return state.currentOrdersPaginationPage
  },
};
