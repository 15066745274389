<template>
  <div class="strains-column">
    <ul>
      <base-strain
        v-for="strain in sortedStrains"
        :key="strain.id"
        :id="strain.id"
        :name="strain.name"
        :feel="strain.feel"
        @delete-strain="deleteStrain"
      ></base-strain>
    </ul>
  </div>
</template>

<script>
import BaseStrain from './BaseStrain.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseStrain
  },

  props: ['search'],
  emits: ['fetch-strains', 'delete-strain', 'strain-count', 'exact-match'],

  data() {
    return {
      isLoading: false,
      error: null
    };
  },

  computed: {
    ...mapGetters(['dbStrains']),
    sortedStrains() {
      if (this.search !== '') {
        let filteredStrains = this.dbStrains.filter(strain => {
          return (
            this.search.toLowerCase() ===
            strain.name.substring(0, this.search.length).toLowerCase()
          );
        });

        this.strainCount(filteredStrains.length);

        let sortedStrains = filteredStrains.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        return sortedStrains;
      } else {
        this.strainCount(`${this.dbStrains.length} total`);
        return '';
      }
    },
  },

  methods: {
    async fetchStrains() {
      try {
        await this.$store.dispatch('fetchStrains');
      } catch (error) {
        this.error = error.message || 'Failed to fetch strains';
      }
    },
    exactMatch() {
      if (this.search !== '') {
        let match = this.dbStrains.filter(strain => {
          return this.search.toLowerCase() === strain.name.toLowerCase()
        })
        let matchObj = {}
        if (match.length === 1) {
          matchObj = {
            exists: true,
            id: match[0].id,
            name: match[0].name,
            feel: match[0].feel,
          }
        } else {
          matchObj = {
            exists: false
          }
        }
        return matchObj
      } else {
        return { exists: false }
      }
    },
    deleteStrain(id) {
      this.$emit('delete-strain', id);
    },
    strainCount(count) {
      this.$emit('strain-count', count);
    }
  },

  watch: {
    search() {
      this.$emit('exact-match', this.exactMatch());
    }
  },

  mounted() {
    this.fetchStrains();
  }
};
</script>

<style lang="scss" scoped>
.strains-column {
  width: 100%;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 40rem;
}

@media screen and (max-width: 620px) {}
</style>
