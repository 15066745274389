import { DateTime } from 'luxon';

export default {
  changePageCategory(state, payload) {
    state.specialsTimeFilter = payload.value;
  },
  changeStoreLocation(state, payload) {
    state.storeLocation = payload.value;
  },
  filterSpecials(state, payload) {
    let results = payload;
    // console.log('results: ', results)
    let d = new Date();
    // d = new Date(2023, 4, 29, 12, 30, 0, 0); // for testing

    let currentDatetimeNumber = d.getTime();
    let filteredSpecials = [];
    results.forEach(result => {
      let startTimestamp = DateTime.fromMillis(result.startNumber);
      let timezoneAdjustedStartNumber = startTimestamp.plus({ hours: 7 }).toMillis();
      let endTimestamp = DateTime.fromMillis(result.endNumber);
      let timezoneAdjustedEndNumber = endTimestamp.plus({ hours: 7 }).toMillis();
      if (
        state.specialsTimeFilter === 'Upcoming' &&
        timezoneAdjustedStartNumber > currentDatetimeNumber
      ) {
        filteredSpecials.push(result);
      } else if (
        state.specialsTimeFilter === 'Current' &&
        timezoneAdjustedStartNumber <= currentDatetimeNumber &&
        timezoneAdjustedEndNumber >= currentDatetimeNumber
      ) {
        filteredSpecials.push(result);
      } else if (
        state.specialsTimeFilter === 'Past' &&
        timezoneAdjustedEndNumber < currentDatetimeNumber
      ) {
        filteredSpecials.push(result);
      }
    });
    let twiceFilteredSpecials = [];
    if (state.storeLocation === 'all') {
      filteredSpecials = filteredSpecials.reverse();
      twiceFilteredSpecials = filteredSpecials;
    } else {
      filteredSpecials.forEach(result => {
        if (
          result.locations !== undefined &&
          result.locations.indexOf(state.storeLocation) !== -1
        ) {
          twiceFilteredSpecials.push(result);
        }
      });
    }
    state.specialsArr = twiceFilteredSpecials.reverse();
  }
};
