const apiUrl = process.env.VUE_APP_DEV_API_URL

export default {
  changePageCategory(context, payload) {
    context.commit('changePageCategory', payload);
  },
  changeStoreLocation(context, payload) {
    context.commit('changeStoreLocation', payload);
  },
  fetchSpecials(context) {
    fetch(apiUrl + '/specials/')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        const results = [];
        for (const id in data) {
          results.push({
            id: data[id].id,
            title: data[id].title,
            locations: data[id].locations.split(','),
            description: data[id].description,
            start: data[id].start_date,
            end: data[id].end_date,
            startNumber: data[id].start_time,
            endNumber: data[id].end_time,
            reoccurringWeekend: data[id].reoccurring_weekend,
            productCategory: data[id].product_category,
          });
        }
        context.commit('filterSpecials', results);
      })
      .catch(error => {
        console.log(error);
        console.log('there was an error in the fetch GET promise chain');
        this.isLoading = false;
        this.error = 'Failed to fetch data please try again later';
      });
  },
  async postSpecial(context, payload) {
    try {
      const response = await fetch(apiUrl + '/specials/', {
        method: 'POST',
        accept: 'application/json',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          title: payload.title,
          description: payload.description,
          locations: payload.locations,
          start_date: payload.start,
          start_time: new Date(payload.start).getTime(),
          end_date: payload.end,
          end_time: new Date(payload.end).getTime(),
          reoccurring_weekend: payload.reoccurringWeekend,
          product_category: payload.productCategory,
        })
      });
      if (response.ok) {
        context.dispatch('fetchSpecials')
        return response.ok
      } else {
        throw new Error('Could not post special!');
      }
    } catch (error) {
      this.error = error.message;
      console.error(error);
    }
  },
  async putSpecial(context, payload) {
    try {
      const response = await fetch(apiUrl + `/specials/${payload.id}/`, {
        method: 'PUT',
        accept: 'application/json',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          title: payload.title,
          description: payload.description,
          locations: payload.locations,
          start_date: payload.start,
          start_time: new Date(payload.start).getTime(),
          end_date: payload.end,
          end_time: new Date(payload.end).getTime(),
          reoccurring_weekend: payload.reoccurringWeekend,
          product_category: payload.productCategory,
        })
      });
      if (response.ok) {
        context.dispatch('fetchSpecials')
        return response.ok
      } else {
        throw new Error('Could not update special!');
      }
    } catch (error) {
      this.error = error.message;
      console.error(error);
    }
  },
  deleteSpecial(context, id) {
    console.log('id: ', id)
    fetch(`${apiUrl}/specials/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application-json',
      },
    }).then((response) => {
      if (response.ok) {
        context.dispatch('fetchSpecials')
      } else {
        throw new Error('Could not delete special!');
      }
    });
  },
};
