export default {
  userId(state) {
    return state.userId;
  },
  username(state) {
    return state.username;
  },
  isStaff(state) {
    return state.isStaff
  },
  role(state) {
    return state.role
  },
  userIsNotTourist(state) {
    return state.role !== 'tourist'
  },
  userLocation(state) {
    return state.userLocation
  },
  email(state) {
    return state.email;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  authError(state) {
    return state.authError
  }
};
