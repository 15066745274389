import router from '../../router'
const apiUrl = process.env.VUE_APP_DEV_API_URL
import { camelCase, capitalCase } from 'change-case';

export default {
  checkLocalToken(context) {
    // occurs on create, not on fetch
    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');
    const isStaff = localStorage.getItem('isStaff');
    const role = localStorage.getItem('role');
    const userLocationId = localStorage.getItem('userLocationId');
    const userLocationSnakeCase = localStorage.getItem('userLocationSnakeCase');
    const userLocationCamelCase = localStorage.getItem('userLocationCamelCase');
    const userLocationCapitalCase = localStorage.getItem('userLocationCapitalCase');
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');
    if (token) {
      context.commit('setUser', {
        userId,
        username,
        isStaff,
        role,
        userLocationId,
        userLocationSnakeCase,
        userLocationCamelCase,
        userLocationCapitalCase,
        email,
        token,
      });
      router.push({ path: '/dashboard' })
    }
    return token
  },
  async postLogin(context, payload) {
    try {
      const response = await fetch(`${apiUrl}/auth/login/`, {
        method: 'POST',
        accept: 'application/json',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password
        })
      });

      const responseData = await response.json();

      if (response.ok) {
        if (!responseData.is_staff) {
          const authError = 'This account does not have permission to enter.'
          context.commit('updateAuthError', { authError: authError })
          context.commit('logout')
        } else {

          // nessary evil to remove circular dependencies on django models
          let userLocationId = ''
          Object.values(context.getters.locations).forEach(location => {
            if (location.name === responseData.location) {
              console.log('match!')
              userLocationId = location.id
            }
            return userLocationId
          })

          const userLocationSnakeCase = responseData.location
          const userLocationCamelCase = camelCase(userLocationSnakeCase)
          const userLocationCapitalCase = capitalCase(userLocationSnakeCase)

          localStorage.setItem('userId', responseData.id);
          localStorage.setItem('username', responseData.username);
          localStorage.setItem('isStaff', responseData.isStaff);
          localStorage.setItem('role', responseData.role);
          localStorage.setItem('userLocationId', userLocationId);
          localStorage.setItem('userLocationSnakeCase', userLocationSnakeCase);
          localStorage.setItem('userLocationCamelCase', userLocationCamelCase);
          localStorage.setItem('userLocationCapitalCase', userLocationCapitalCase);
          localStorage.setItem('email', responseData.email);
          localStorage.setItem('token', responseData.tokens.access);

          context.commit('setUser', {
            userId: responseData.id,
            username: responseData.username,
            isStaff: responseData.is_staff,
            role: responseData.role,
            userLocationId: userLocationId,
            userLocationSnakeCase: userLocationSnakeCase,
            userLocationCamelCase: userLocationCamelCase,
            userLocationCapitalCase: userLocationCapitalCase,
            email: responseData.email,
            token: responseData.tokens.access,
            authError: '',
          });
          router.push({ path: '/dashboard' })
        }
      } else {
        const authError = 'Username or Password is incorrect.'
        context.commit('updateAuthError', { authError: authError })
      }
      return response

    } catch (error) {
      let authError = error
      if (error.message === 'Failed to fetch') {
        authError = 'The authentication server is not responding. Please contact tech support.'
      }
      context.commit('updateAuthError', { authError, })
    }
  },
};
