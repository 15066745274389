<template>
  <div class="form-column">
    <base-card>
      <form @submit.prevent="">
        <div
          v-if="storeLocation === 'all'"
          class="location-selection"
        >
          <div
            v-for="location in defaultLocations"
            :key="location"
            class="row location-checkbox"
          >
            <input
              type="checkbox"
              :id="`select-${location}`"
              :value="capitalCase(location)"
              v-model="locationsInput"
            />
            <label :for="`select-${location}`">{{ capitalCase(location) }}</label>
          </div>
        </div>

        <div class="form-control">
          <label for="title">Title</label>
          <input
            id="title"
            name="title"
            type="text"
            v-model.trim="titleInput"
          />
        </div>
        <div class="form-control">
          <label for="description">Description</label>
          <textarea
            id="description"
            name="description"
            rows="3"
            v-model.trim="descInput"
          ></textarea>
        </div>
        <div class="form-control">
          <label for="productCategory">Product Category</label>
          <v-select
            class="selector cap"
            id="vs-product-category"
            :clearable="false"
            :options="productCategoryList"
            v-model="productCategory"
          />

        </div>
        <div class="form-control timing">
          <label for="title">Timing:</label>

          <input
            type="radio"
            id="window"
            name="timing"
            value="window"
            v-model="timingSelection"
          />
          <label for="window">Window</label>

          <input
            type="radio"
            id="every-day"
            name="timing"
            value="every-day"
            v-model="timingSelection"
          />
          <label for="every-day">Every Day</label>

          <input
            type="radio"
            id="reoccurring"
            name="timing"
            value="reoccurring"
            v-model="timingSelection"
          />
          <label for="reoccurring">Reoccurring Weekend</label>
        </div>

        <div
          v-if="timingSelection === 'window'"
          class="form-control dates"
        >
          <div class="row">
            <label for="datetime-start">From</label>
            <input
              type="datetime-local"
              v-model="start"
            />
          </div>
          <div class="row">
            <label for="datetime-end">Until</label>
            <input
              type="datetime-local"
              v-model="end"
            />
          </div>
        </div>

        <div
          v-if="timingSelection === 'reoccurring'"
          class="form-control weekends"
        >
          <div
            class="row"
            v-for="weekend in weekends"
            :key="weekend.id"
          >
            <input
              type="radio"
              :id="weekend.id"
              name="weekend"
              :value="weekend.value"
              v-model="reoccurringWeekend"
            />
            <label :for="weekend.id">{{ weekend.label }}</label>
          </div>
        </div>

        <div class="button-container">
          <base-button
            v-if="editMode"
            @click="postOrPutSpecial('put')"
          >
            Update Special
          </base-button>
          <base-button @click="postOrPutSpecial('post')">{{ editMode ? 'New Special' : 'Add Special' }}
          </base-button>
          <base-button
            v-if="editMode"
            @click="cancelEditMode"
            class="inverse-button"
          >
            Cancel
          </base-button>
        </div>
      </form>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalCase } from 'change-case'
import { DateTime } from 'luxon';


export default {
  props: [
    'storeLocation',
    'editMode',
    'editSpecialValues'
  ],
  emits: [
    'error-modal',
    'edit-off'
  ],
  data() {
    return {
      objId: '',
      titleInput: '',
      descInput: '',
      locationsInput: [],
      timingSelection: 'window',
      start: '',
      end: '',
      weekends: [
        { id: '1st-weekend', value: '1st Weekend', label: '1st' },
        { id: '2nd-weekend', value: '2nd Weekend', label: '2nd' },
        { id: '3rd-weekend', value: '3rd Weekend', label: '3rd' },
        { id: '4th-weekend', value: '4th Weekend', label: '4th' },
        { id: 'last-weekend', value: 'Last Weekend', label: 'Last' }
      ],
      reoccurringWeekend: '',
      productCategoryList: [
        'flower',
        'preroll',
        'cartridge',
        'concentrate',
        'edible',
        'tincture',
        'topical',
        'clone',
        'multiple categories'
      ],
      productCategory: '',
    };
  },
  computed: {
    ...mapGetters([
      'userIsNotTourist',
      'defaultLocations',
    ]),
    compStart() {
      return this.timingSelection === 'every-day'
        ? '2000-01-01T00:01'
        : this.start;
    },
    compEnd() {
      return this.timingSelection === 'every-day'
        ? '3000-01-01T00:01'
        : this.end;
    },
  },
  methods: {
    timezoneAdjust(direction, windowBoundingTime) {
      let inputDate = DateTime.fromISO(windowBoundingTime);
      let outputDate = ''
      if (direction === 'toUTC') {
        outputDate = inputDate.minus({ hours: 7 });
      } else if (direction === 'toPacific') {
        outputDate = inputDate.plus({ hours: 7 });
      }
      return outputDate.toFormat('yyyy-MM-dd\'T\'HH:mm');
    },
    capitalCase,
    editSpecial() {
      this.objId = this.editSpecialValues.id;
      this.titleInput = this.editSpecialValues.title;
      this.descInput = this.editSpecialValues.description;
      this.locationsInput = this.editSpecialValues.locations;
      this.timingSelection = this.editSpecialValues.timingSelection;
      this.reoccurringWeekend = this.editSpecialValues.reoccurringWeekend;
      this.start = this.timezoneAdjust('toPacific', this.editSpecialValues.start)
      this.end = this.timezoneAdjust('toPacific', this.editSpecialValues.end)
      this.productCategory = this.editSpecialValues.productCategory;
    },
    clearEditSpecial() {
      this.objId = '';
      this.titleInput = '';
      this.descInput = '';
      this.locationsInput = [];
      this.timingSelection = 'window';
      this.reoccurringWeekend = '';
      this.start = '';
      this.end = '';
      this.productCategory = '';
    },
    cancelEditMode() {
      this.clearEditSpecial();
      this.$emit('edit-off');
    },
    validateInput() {
      if (this.userIsNotTourist) {
        if (
          (this.locationsInput.length === 0 && this.storeLocation == 'all') ||
          this.titleInput === '' ||
          this.descInput === '' ||
          (this.timingSelection === 'window' &&
            (this.start === '' || this.end === '')) ||
          (this.timingSelection === 'reoccurring' &&
            this.reoccurringWeekend === '')
        ) {
          this.$emit('error-modal');
          return false;
        }
        return true;
      }
      return false;
    },
    processValues() {
      if (this.storeLocation !== 'all' && !this.editMode) {
        this.locationsInput = [`${this.storeLocation}`];
      }
      this.locationsInput = `${this.locationsInput}`;
      let obj = {
        title: this.titleInput,
        description: this.descInput,
        locations: this.locationsInput,
        timingSelection: this.timingSelection,
        start: this.compStart,
        end: this.compEnd,
        reoccurringWeekend: this.reoccurringWeekend,
        productCategory: this.productCategory,
      };
      if (this.editMode) {
        obj = {
          ...obj,
          id: this.objId,
        };
      }
      return obj
    },
    clearFields() {
      this.clearEditSpecial();
      this.$emit('edit-off');
    },
    async postOrPutSpecial(method) {
      if (!this.validateInput()) {
        return;
      }
      const data = this.processValues();
      if (data.timingSelection === 'window') {
        data.start = this.timezoneAdjust('toUTC', data.start);
        data.end = this.timezoneAdjust('toUTC', data.end);
      }
      const resOk = await this.$store.dispatch(`${method}Special`, data);
      if (resOk) {
        this.clearFields();
      }
    },
    confirmError() {
      this.inputIsInvalid = false;
    },
  },
  watch: {
    editMode() {
      if (this.editMode) {
        this.editSpecial();
      } else {
        this.clearEditSpecial();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-column {
  // margin: 0 20px;
  width: 40rem;

  .title {
    display: flex;
    justify-content: center;
  }

  h2 {
    margin-block: 0.2em;
  }

  label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }

  .location-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    input {
      min-width: 12px;
    }

    label {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}

.location-selection {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

// .location-selection .group-1,
// .location-selection .group-2 {
//   display: flex;
//   width: 50%;
//   justify-content: space-around;
// }

.row {
  display: flex;
  /* min-width: 80px; */
}

.location-selection .row {
  min-width: 0;
}

.location-checkbox {
  margin: 3px;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  padding: 0.15rem;
  border: 1px solid #ccc;
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: forestgreen;
}

button {
  margin-right: 10px;
}

button:hover {
  color: forestgreen;
}

.form-control {
  margin: 1rem 0;
}

.dates,
.weekends {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0;
}

.dates label {
  margin-bottom: 0;
  margin-right: 8px;
}

.timing {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.timing input {
  width: initial;
}

.weekends input {
  min-width: 12px;
  /* margin: 0 4px; */
}

.inverse-button {
  background-color: white;
  color: forestgreen;
}

.inverse-button:hover {
  background-color: #d4ffda;
}

@media (min-width: 1280px) {
  .form-column {
    position: sticky;
    top: initial;
  }
}

@media screen and (max-width: 620px) {
  .form-column {
    margin: 0;
    width: 95vw;
  }

  .location-selection {
    flex-direction: column;
  }

  .location-selection .group-1,
  .location-selection .group-2 {
    width: 100%;
    margin-top: 8px;
  }

  .dates {
    flex-direction: column;
  }

  .dates label {
    width: 50px;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  // #product-category {
  //   border: solid 1px #ccc !important;
  // }
}
</style>
