export default {
  pageCategory(state) {
    return state.specialsTimeFilter;
  },
  storeLocation(state) {
    return state.storeLocation;
  },
  specialsArr(state) {
    return state.specialsArr;
  }
};
