<template>
  <router-view></router-view>
  <router-view name="body"></router-view>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch('getLocations');
    // this.$store.dispatch('checkLocalLocations');
    this.$store.dispatch('checkLocalToken');
  }
};
</script>

<style lang="scss">
@import 'src/components/UI/_themes.scss';
@import 'src/components/UI/_global.scss';
</style>
