<template>
  <div id="flex-container">
    <div id="column-container">
      <base-card class="outline">
        <img
          id="logo"
          src="../../../assets/wod-forest-green.png"
        />
        <h1>ADMIN DASHBOARD</h1>
      </base-card>
      <base-card class="outline">
        <h2>Login</h2>
        <p>{{ authError }}</p>
        <form @submit.prevent="">
          <p v-if="incorrect">
            The password that you tried is incorrect, please try again or contact
            tech support on our Discord server.
          </p>
          <div class="form-control">
            <label for="email"> Email: </label>
            <div class="input-flex-row">
              <input
                type="text"
                id="email"
                name="email"
                autofocus
                autocomplete="off"
                v-model.trim="emailInput"
              />
            </div>
          </div>
          <div class="form-control">
            <label for="password">Password: </label>
            <div class="input-flex-row">
              <input
                type="password"
                id="password"
                name="password"
                v-model.trim="passwordInput"
              />
            </div>
          </div>
          <div>
            <base-button @click="submitSignIn">Enter</base-button>
          </div>
        </form>
      </base-card>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    // BaseSpecial
  },
  data() {
    return {
      incorrect: false,
      emailInput: '',
      passwordInput: '',
    };
  },
  computed: {
    ...mapGetters(['authError', 'isStaff']),
  },
  methods: {
    async submitSignIn() {
      if (this.emailInput === '') {
        this.$store.commit('updateAuthError', 'Enter email.');
        return;
      } else if (this.password === '') {
        this.$store.commit('updateAuthError', 'Enter Password.');
        return;
      }
      try {
        await this.$store.dispatch('postLogin', {
          email: this.emailInput.toLowerCase(),
          password: this.passwordInput,
        });

      } catch (error) {
        this.error = error.message || 'Failed to receive auth token';
      }
    },
  },

  mounted() {
    // set page title
    document.title = 'WOD Admin';

    // set 'app-background' class to body tag
    let bodyElement = document.body;
    bodyElement.classList.add('app-background');

    // check for active theme
    let htmlElement = document.documentElement;
    let theme = this.$route.path;
    theme = theme.substring(1, theme.length);
    htmlElement.setAttribute('theme', theme);
  },
};
</script>

<style lang="scss" scoped>
@import 'src/components/UI/_themes.scss';

#flex-container {
  display: flex;
  justify-content: center;
}

#column-container {
  display: flex;
  flex-direction: column;
}

.input-flex-row {
  display: flex;
  justify-content: center;
}

.outline {
  border: solid 3px var(--accent-background);
  text-align: center;
  margin: 10px 20px 10px 20px !important;
}

h1,
h2 {
  color: var(--accent-background);
}

p {
  color: red;
}

.form-control {
  display: flex;
  flex-direction: column;
}

input {
  color: var(--accent-background);
  border: solid 2px var(--accent-background);
  margin-bottom: 10px;
  width: 240px;
}

label {
  color: var(--accent-background);
}

img {
  width: 80%;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: large;
  }

  h2 {
    font-size: larger;
    margin-top: 0px;
  }

  // img {
  //   width: 200px;
  // }
}
</style>
